import { customBaseQuery, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

const TAG_TYPES = [
  'Batches',
  'Batch',
  'Bestsellers',
  'KeyMetricsKPI',
  'WeeklyPackKPI',
  'Products',
  'ProductSkuLocation',
  'LocationSku',
  'Location',
  'LocationProducts',
  'LocationDepartments',
  'ProductLocations',
  'ProductSkuLocations',
  'AssortmentRemovals',
  'LocationDepartmentSku',
  'ProductStockAnalysis',
  'GetFilterTemplates',
  'ChildPeakView',
];

export const replenishmentApi = createApi({
  reducerPath: 'replenishmentApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled({
      key: 'replenishment-api-v2',
      defaultValue: true,
    });
    const uri = isV2Enabled ? 'v2/replenishment' : 'replenishment';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: () => ({}),
});
