import { CommonTranslationFunction } from '@autone/translations';
import {
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_READONLY,
  REPLENISHMENT_STANDARD,
} from '@autone/utils';

import { ReplenishmentTranslationFunction } from '../types/translations';

export const APP_NAME = 'replenishment';

export const getScopeDictionary = (
  t: ReplenishmentTranslationFunction,
  common: CommonTranslationFunction,
) => ({
  name: { name: t('scope.name') },
  distribution_points: { name: t('scope.distribution-points') },
  location_types: { name: t('scope.location-types') },
  regions: { name: t('scope.regions') },
  countries: { name: t('scope.countries') },
  locations: { name: t('scope.locations') },
  departments: { name: t('scope.departments') },
  sub_departments: { name: t('scope.sub-departments') },
  seasons: { name: t('scope.seasons') },
  products: { name: t('scope.products') },
  events: { name: t('scope.events') },
  brands: { name: t('scope.brands') },
  coverage_options: { name: t('scope.coverage-options') },
  remove_markdown_products: { name: common('remove-markdown-products') },
});

export const INCL_ZERO_UNITS_SIDEPANEL_STORAGE_KEY =
  'include-zero-replen-units-sidepanel';

export const INCL_ZERO_UNITS_PRODUCT_TABLE_KEY =
  'include-zero-replen-units-product-table';

export const PRODUCT_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productsTable.advancedFilters';

export const PRODUCT_LOCATIONS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productLocationsTable.advancedFilters';

export const PRODUCT_SKUS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productSkusTable.advancedFilters';

export const LOCATIONS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationsTable.advancedFilters';

export const LOCATION_DEPARTMENTS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationDepartmentsTable.advancedFilters';

export const LOCATION_PRODUCTS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationProductsTable.advancedFilters';

export const LOCATION_SKUS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationSkusTable.advancedFilters';

export const MAX_REPLEN_NAME_CHARACTERS = 25;
export const COLUMN_LIMIT = 3;
export const DEFAULT_ALLOCATION_ERROR = 'OVER_ALLOCATION';
export const REPLENISHMENT_PERMISSIONS = [
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_STANDARD,
  REPLENISHMENT_READONLY,
];
export const ENABLED_COLUMN_KEY = 'enabled_sort';

export const SIDE_PANEL_TABLE_HEIGHT = 800;
export const CHILD_PEAK_VIEW_DATA_NOT_FOUND = 'CHILD_PEEK_VIEW_DATA_NOT_FOUND';
